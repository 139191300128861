const React = require(`react`)
const { KeyboardProvider } = require(`./src/components/CoreUI/KeyboardContext`)
const {
  MarketingProvider,
} = require(`./src/components/CoreUI/MarketingContext`)
const { MediaProvider } = require(`./src/components/CoreUI/MediaContext`)

const inProduction = process.env.NODE_ENV === `production`

// Core-UI renders CallRail's JS to the body at build
const swapPhoneNumbers = () => {
  if (inProduction && `CallTrk` in window) {
    window.CallTrk.swap()
  }
}

exports.onInitialClientRender = () => {
  swapPhoneNumbers()
  window.dispatchEvent(new CustomEvent("domrendered"))
}

exports.onRouteUpdate = () => {
  swapPhoneNumbers()
  if (process.env.NODE_ENV === `production` && `dataLayer` in window) {
    setTimeout(() => {
      window.dataLayer.push({ event: `gatsby-route-change` })
    }, 50)
  }
}

// core
exports.wrapPageElement = ({ element }) => (
  <MediaProvider>
    <MarketingProvider>
      <KeyboardProvider>{element}</KeyboardProvider>
    </MarketingProvider>
  </MediaProvider>
)
