import * as React from "react"

const BREAK_POINTS = {
  xs: { min: 0, max: 575 },
  sm: { min: 576, max: 767 },
  md: { min: 768, max: 991 },
  lg: { min: 992, max: 1199 },
  xl: { min: 1200, max: 1599 },
  xxl: { min: 1600, max: 99999 },
}

const DEVICE_TYPES = {
  phones: /Android|iPhone|iPod|Mini|Mobile/,
  tablets: /iPad|Note|Tablet/,
  desktop: /Chromebook|Mac|OS X|Windows/,
}

const MOTION_QUERY = `(prefers-reduced-motion: reduce)`

type Fallback = string | number | boolean | object | Function
type WindowProp = string | number | boolean | object | Function

function safelyGetWindowProp(
  prop: string,
  fallback: Fallback
): WindowProp | Fallback {
  if (typeof window !== `undefined`) {
    return window[prop]
  }
  return fallback
}

interface MediaProps {
  children: React.ReactNode
}

export type TMediaContext = {
  agent: string
  breakpoint: string
  browser: string
  cores: number
  device: string
  height: WindowProp
  isTouch: boolean
  orientation: string
  pixelRatio: number
  reduceMotion: boolean
  vendor: string
  width: WindowProp
}

export const MediaContext = React.createContext<TMediaContext>(null)

export class MediaProvider extends React.Component<MediaProps, TMediaContext> {
  state: TMediaContext = {
    agent: `ssr`,
    breakpoint: `xs`,
    browser: `ssr`,
    cores: 1,
    device: `ssr`,
    height: safelyGetWindowProp(`innerHeight`, 1),
    isTouch: false,
    orientation: `ssr`,
    pixelRatio: 1,
    reduceMotion: false,
    vendor: `ssr`,
    width: safelyGetWindowProp(`innerWidth`, 1),
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.describeMedia()
      window.addEventListener(`resize`, this.handleResize)
    }
  }

  private describeMedia = (): void => {
    const agent = window.navigator.userAgent
    const height = window.innerHeight
    const width = window.innerWidth

    this.setState({
      agent,
      breakpoint: this.getBreakpoint(width),
      browser: window.navigator.appCodeName,
      cores: window.navigator.hardwareConcurrency,
      device: this.guessDevice(agent),
      height,
      isTouch: this.isTouchDevice(),
      orientation: this.getOrientation(width, height),
      pixelRatio: window.devicePixelRatio,
      reduceMotion: window.matchMedia(MOTION_QUERY).matches,
      vendor: window.navigator.vendor,
      width,
    })
  }

  private getBreakpoint = (width: Number): string => {
    for (let point in BREAK_POINTS) {
      const { min, max } = BREAK_POINTS[point]
      if (width >= min && width <= max) {
        return point
      }
    }
  }

  private getOrientation = (width: number, height: number): string => {
    return width >= height ? `landscape` : `portrait`
  }

  private guessDevice = (agent: string): string => {
    for (let device in DEVICE_TYPES)
      if (DEVICE_TYPES[device].test(agent)) return device
  }

  private handleResize = (): void => {
    const height = window.innerHeight
    const width = window.innerWidth
    const breakpoint = this.getBreakpoint(width)
    const orientation = this.getOrientation(width, height)

    this.setState({ breakpoint, height, orientation, width })
  }

  private isTouchDevice = (): boolean => {
    if (`ontouchstart` in window || `DocumentTouch` in window) {
      return true
    }
    return false
  }

  render() {
    const {
      props: { children },
      state,
    } = this

    return (
      <MediaContext.Provider value={state}>{children}</MediaContext.Provider>
    )
  }
}
