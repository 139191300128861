import * as React from "react"

export type UTMParam =
  | "utm_campaign"
  | "utm_content"
  | "utm_medium"
  | "utm_source"
  | "utm_term"

export type Param = Map<UTMParam, string>

export type ParamObj = {
  key: UTMParam
  value: string
}

export type AllParamsObj = {
  [k in UTMParam]?: string
}

export interface MarketingState {
  hasParams: boolean
  params: Param
}

export interface MarketingHelpers {
  getUtmParam: (param: UTMParam) => string
  getAllUtmParams: (serialize?: boolean) => ParamObj[] | string
  getStringifedUtmParams: (filterer?: (ParamObj) => boolean) => string | null
  getUtmParamsObject: () => AllParamsObj
}

export type TMarketingContext = MarketingState & MarketingHelpers

const UTM_PARAM_LIST: string[] = [
  `utm_campaign`,
  `utm_content`,
  `utm_medium`,
  `utm_source`,
  `utm_term`,
]

export const MarketingContext = React.createContext<TMarketingContext>(null)

interface Props {
  [key: string]: unknown
  children?: React.ReactNode
}

export class MarketingProvider extends React.Component<Props, MarketingState> {
  constructor(props) {
    super(props)
    this.state = {
      hasParams: false,
      params: new Map(),
    }
  }

  componentDidMount() {
    this.queryUtmParams()
  }

  private queryUtmParams = (): void => {
    const params = new Map()
    const { search } = window.location

    if (search.includes(`?`)) {
      const paramArr = search.substring(1).split(`&`)

      paramArr.forEach(param => {
        const [key, value] = param.split("=")

        if (UTM_PARAM_LIST.includes(key)) {
          params.set(key, decodeURIComponent(value))
        }
      })
    }

    this.setState({ hasParams: params.size > 1, params })
  }

  public getUtmParam = (param: UTMParam): string => {
    const { params } = this.state

    if (params.has(param)) {
      const value = params.get(param)
      return value
    }
    return ``
  }

  public getAllUtmParams = (serialize?: boolean): ParamObj[] | string => {
    const { params } = this.state

    if (params.size > 0) {
      const keyArr: UTMParam[] = Array.from(params.keys())
      const paramArr: ParamObj[] = keyArr.map(key => ({
        key,
        value: params.get(key),
      }))

      if (serialize) {
        return JSON.stringify(paramArr)
      }
      return paramArr
    } else {
      if (serialize) {
        return JSON.stringify([])
      }
      return []
    }
  }

  public getStringifedUtmParams = (
    filterer?: ({ key, value }) => boolean
  ): string | null => {
    const paramArr: ParamObj[] | string = this.getAllUtmParams()
    if (Array.isArray(paramArr)) {
      const paramString = paramArr.reduce((acc, { key, value }) => {
        if (filterer) {
          return filterer({ key, value })
            ? `${acc}${acc.length ? `,` : ``}${key}:"${value}"`
            : ``
        } else {
          return `${acc}${acc.length ? `,` : ``}${key}:"${value}"`
        }
      }, ``)
      return paramString
    }
    return null
  }

  public getUtmParamsObject = (): AllParamsObj => {
    const params = this.getAllUtmParams()
    if (Array.isArray(params)) {
      return params.reduce(
        (obj: AllParamsObj, param: ParamObj) => ({
          ...obj,
          [param.key]: param.value,
        }),
        {}
      )
    }
    return {}
  }

  render() {
    const {
      props,
      state,
      getAllUtmParams,
      getStringifedUtmParams,
      getUtmParam,
      getUtmParamsObject,
    } = this

    return (
      <MarketingContext.Provider
        value={{
          ...state,
          getAllUtmParams,
          getStringifedUtmParams,
          getUtmParam,
          getUtmParamsObject,
        }}
      >
        {props.children}
      </MarketingContext.Provider>
    )
  }
}
