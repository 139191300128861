import * as React from "react"

export type KeyboardInteractionType = "keyup" | "keydown"

export interface KeyboardProps {
  children?: React.ReactNode
}

export interface HotKeyListener {
  key: string
  handler: (event: KeyboardEvent) => void
  type: KeyboardInteractionType
}

export interface KeyboardContextState {
  disabled: boolean
  disabledKeys: null | string[]
  event: KeyboardEvent | {}
  listeners: HotKeyListener[] | []
}

export interface KeyboardContextHelpers {
  addHotKey: (newHotKey: HotKeyListener) => void
  disableHotKeys: (key?: string | string[]) => void
  enableHotKeys: (key?: string | string[]) => void
  hasListenerForKey: (key: string) => boolean
  removeHotKey: (key: string) => void
  toggleHotKeys: () => boolean
}

export type TKeyboardContext = KeyboardContextState & KeyboardContextHelpers

export const KeyboardContext = React.createContext<TKeyboardContext>(null)



export class KeyboardProvider extends React.Component<
  KeyboardProps,
  KeyboardContextState
> {
  constructor(props: KeyboardProps) {
    super(props)
    this.state = {
      disabled: false,
      disabledKeys: [],
      event: {},
      listeners: [],
    }
  }

  componentDidMount() {
    window.addEventListener(`keyup`, event => this.handleKey(event))
  }

  componentWillUnmount() {
    window.removeEventListener(`keyup`, event => this.handleKey(event))
  }

  private handleKey(event: KeyboardEvent): void {
    const { disabled, disabledKeys, listeners } = this.state

    if (!disabled && !disabledKeys.includes(event.key)) {
      const hotKey: HotKeyListener = listeners.find(
        ({ key, type }) => type === event.type && key === event.key
      )

      if (hotKey) {
        hotKey.handler(event)
      }
    }

    this.setState({ event })
  }

  public addHotKey = (newHotKey: HotKeyListener): void => {
    const hotKey: HotKeyListener = Object.assign({ type: `keyup` }, newHotKey)

    const listeners: HotKeyListener[] = this.state.listeners
    listeners.push(hotKey)

    this.setState({ listeners })
  }

  public disableHotKeys = (key?: string | string[]): void => {
    if (key) {
      const disabledKeys: string[] = this.state.disabledKeys.concat(key)
      this.setState({ disabledKeys })
    } else {
      this.setState({ disabled: true })
    }
  }

  public enableHotKeys = (key?: string | string[]): void => {
    if (key) {
      if (Array.isArray(key)) {
        const disabledKeys: string[] = this.state.disabledKeys.filter(
          disabledKey => !key.includes(disabledKey)
        )
        this.setState({ disabledKeys })
      } else {
        const disabledKeys: string[] = this.state.disabledKeys.filter(
          dKey => key !== dKey
        )
        this.setState({ disabledKeys })
      }
    } else {
      this.setState({ disabled: false })
    }
  }

  public hasListenerForKey = (key: string): boolean => {
    const { listeners } = this.state

    const existingListener: HotKeyListener = listeners.find(
      (listener: HotKeyListener) => listener.key === key
    )

    if (existingListener) {
      return true
    }
    return false
  }

  public removeHotKey = (key: string): void => {
    const { listeners } = this.state

    const newListeners: HotKeyListener[] = listeners.filter(
      (listener: HotKeyListener) => {
        listener.key !== key
      }
    )

    this.setState({ listeners: newListeners })
  }

  public toggleHotKeys = (): boolean => {
    const { disabled } = this.state

    if (disabled) {
      this.enableHotKeys()
      return true
    }
    this.disableHotKeys()
    return false
  }

  render() {
    const {
      props: { children },
      state,
      addHotKey,
      disableHotKeys,
      enableHotKeys,
      hasListenerForKey,
      removeHotKey,
      toggleHotKeys,
    } = this

    return (
      <KeyboardContext.Provider
        value={{
          ...state,
          addHotKey,
          disableHotKeys,
          enableHotKeys,
          hasListenerForKey,
          removeHotKey,
          toggleHotKeys,
        }}
      >
        {children}
      </KeyboardContext.Provider>
    )
  }
}
